import React from "react";
import { FireIcon, BanIcon, LocationMarkerIcon } from "@heroicons/react/outline";

const Error = ({ code }) => {
	let status;
	let description;
	let icon;

	switch (code) {
		case 401:
			status = "Unauthorized";
			description = "You don't have permission to access this page";
			icon = <BanIcon className="h-20 w-20 text-purple-500" />;
			break;
		case 403:
			status = "Forbidden";
			description = "You don't have permission to access this page";
			icon = <BanIcon className="h-20 w-20 text-purple-500" />;
			break;
		case 404:
			status = "Not Found";
			description = "The page you're looking for couldn't be found";
			icon = <LocationMarkerIcon className="h-20 w-20 text-purple-500" />;
			break;
		default:
			status = "Internal Server Error";
			description = "Something went wrong on our end";
			icon = <FireIcon className="h-20 w-20 text-purple-500" />;
			break;
	}

	return (
		<div className="flex flex-col justify-center items-center h-screen p-4 text-center select-none">
			{icon}
			<h1 className="font-extrabold text-4xl text-gray-800 my-2">{code} &bull; {status}</h1>
			<p className="text-xl font-bold text-gray-600 mb-3">{description}</p>
			<a
				href="/"
				className="px-2 py-1 rounded-lg text-purple-500 border-2 border-purple-500 font-bold hover:bg-purple-500 hover:text-white transition duration-250"
			>
				Go to Homepage
			</a>
		</div>
	);
};

export default Error;
