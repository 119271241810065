import React, { useRef, useState } from "react";
import { CloudUploadIcon, RefreshIcon, ExclamationCircleIcon } from "@heroicons/react/solid";

const Home = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [showError, setShowError] = useState(false);

	const handleReset = () => {
		setData(null);
	};
	const inputRef = useRef(null);
	const handleSubmitGene = async () => {
		let inputValue = inputRef.current.value;

		if (!inputValue) {
			setErrorMessage("Please provide a gene symbol");
			setShowError(true);
			return;
		}

		setLoading(true);
		setShowError(false);

		try {
			let response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/overlap/${inputValue}`);
			let json = await response.json();

			if (response.status === 200)
				setData(json);
			else {
				setErrorMessage(json.message);
				setShowError(true);
			}
		} catch (err) {
			setErrorMessage("An unknown error occurred");
			setShowError(true);
		}

		setLoading(false);
	};

	return (
		<div className="pt-12 md:pt-24 flex flex-col justify-center select-none pb-24">
			<div className="max-w-8xl w-full mx-auto px-8 sm:px-6 lg:px-4 xl:px-2 mb-4">
				<h1 className="decoration-clone font-black text-4xl text-purple-500 my-2">
					Panel <span className="strikethrough border-red-500">App</span> Overlap
				</h1>
				<p className="font-sm font-bold text-gray-500 mb-2 -mt-1">
					An{" "}
					<a href="https://gitlab.com/gene-visualiser" target="_blank" className="text-purple-500 font-extrabold">
						open source
					</a>
					{" "}tool developed by{" "}
					<a href="https://alexgokhale.com" target="_blank" className="text-purple-500 font-extrabold">
						Alex&nbsp;Gokhale
					</a>.
				</p>
				<p className="font-bold text-lg text-gray-700">
					A tool to display gene overlap between panels.
				</p>
				<p className="font-bold text-lg text-gray-700">
					Enter a gene below to view panels containing it (if any).
				</p>
				<p className="font-bold text-lg text-gray-700">
					Use{" "}
					<kbd className="text-xs bg-gray-200 border-2 rounded-md p-1 border-gray-500">Shift</kbd> +{" "}
					<kbd className="text-xs bg-gray-200 border-2 rounded-md p-1 border-gray-500">Scroll</kbd>{" "}
					to move horizontally between panels.
				</p>
			</div>
			{data ? (
				<>
					<div className="max-w-8xl w-full mx-auto mb-4 flex">
						<button onClick={handleReset} className={"rounded-lg bg-purple-500 p-2 flex-grow flex justify-center items-center h-14 text-md " +
						"text-white font-extrabold hover:bg-purple-600 transition duration-250 disabled:cursor-not-allowed disabled:bg-purple-400"}>
							<RefreshIcon className="h-5 w-5 -ml-1 mr-2 inline-block" />
							Start Over
						</button>
					</div>
					{data.panels.length === 0 ? (
						<div className="max-w-8xl w-full mx-auto mb-4 flex-col">
							<div className="border-2 border-orange-500 bg-white shadow-md rounded-lg p-3">
								<p className="font-bold text-gray-700">
									No panels contained the gene symbol provided
								</p>
							</div>
						</div>
					) : (
						<div className="max-w-8xl w-full mx-auto overflow-x-auto flex flex-row gap-4">
							{data.panels.map(p => (
								<div key={p.panel.id} className="flex-grow flex-shrink-0 border-2 border-purple-500 shadow-md rounded-lg w-64 bg-white p-2">
									<a
										title={p.panel.name} target="_blank" rel="noopener"
										href={`https://nhsgms-panelapp.genomicsengland.co.uk/panels/${p.panel.id}/v${p.panel.version}`}
										className="font-bold mb-2 block text-purple-500 hover:text-purple-600 whitespace-nowrap overflow-hidden overflow-ellipsis">
										<span className="text-sm text-black">({p.panel.id})</span>
										{" "}{p.panel.name}
									</a>
									{p.entities.map((e, index) => (
										<div key={e.entity_name} className={`p-2 shadow-md ${index === 0 ? "bg-green-100" : "bg-purple-100"} mb-2 rounded-lg`}>
											<a
												href={`https://nhsgms-panelapp.genomicsengland.co.uk/entities/${e.entity_name}`}
												className={`font-bold ${index === 0 ? "text-green-700" : "text-purple-700"}`}
												target="_blank" rel="noopener"
											>
												{e.entity_name}
											</a>
										</div>
									))}
								</div>
							))}
						</div>
					)}
				</>
			) : (
				<>
					<div className="max-w-8xl w-full mx-auto mb-4 px-8 sm:px-6 lg:px-4 xl:px-2 flex-col">
						<div className="border-2 flex items-center border-purple-500 bg-white shadow-md rounded-lg p-3">
							<ExclamationCircleIcon className="w-6 h-6 text-purple-500 flex-shrink-0 mr-2" />
							<p className="font-bold text-purple-500">
								Currently, only the provided gene is highlighted across all panels.
								A way to view all genes which overlap will be coming soon.
							</p>
						</div>
					</div>
					<div className="max-w-8xl w-full mx-auto px-8 sm:px-6 lg:px-4 xl:px-2 mb-4 flex">
						<input
							placeholder="Enter a gene symbol..." disabled={loading} ref={inputRef}
							className={"shadow-md flex-grow resize-none rounded-lg border-2 placeholder-gray-400 focus:bg-purple-100 focus:placeholder-gray-500 " +
							"border-purple-500 p-3 focus:outline-none text-purple-500 transition duration-250 font-medium disabled:cursor-not-allowed"}
						/>
					</div>
					<div className={`max-w-8xl w-full mx-auto px-8 sm:px-6 lg:px-4 xl:px-2 mb-4 ${showError ? "flex" : "hidden"}`}>
						<div className="bg-red-500 p-3 rounded-lg flex-1 text-white font-bold flex items-center justify-between">
							<div className="items-center flex">
								<svg className="h-6 w-6 inline-block mr-2 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
									<path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
								</svg>
								{errorMessage}
							</div>
							<svg
								fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={() => setShowError(false)}
								className="h-6 w-6 flex-shrink-0 rounded-md hover:bg-red-400 hover:cursor-pointer transition duration-250">
								<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
							</svg>
						</div>
					</div>
					<div className="max-w-8xl w-full mx-auto px-8 sm:px-6 lg:px-4 xl:px-2 mb-4 flex">
						<button onClick={handleSubmitGene} disabled={loading} className={"rounded-lg bg-purple-500 p-2 flex-grow flex justify-center items-center h-14 text-md " +
						"text-white font-extrabold hover:bg-purple-600 transition duration-250 disabled:cursor-not-allowed disabled:bg-purple-400"}>
							{loading ? (
								<>
									<svg className="animate-spin h-5 w-5 -ml-1 mr-2 text-white" fill="none" viewBox="0 0 24 24">
										<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" />
										<path
											className="" fill="currentColor"
											d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
										/>
									</svg>
									Loading
								</>
							) : (
								<>
									<CloudUploadIcon className="h-5 w-5 -ml-1 mr-2 inline-block" />
									Submit
								</>
							)}
						</button>
					</div>
				</>
			)}
		</div>
	);
};

export default Home;
